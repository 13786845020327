<template>
    <div class="loan-application">
        <div class="loan-application__header">
            <router-link to="/" class="logo" />
        </div>
        <StepTemplate hideBurger>
            <template v-slot:title>
                {{ title }}
            </template>
            <template v-slot:top>
                <Progress class="loan-application__top-progress"/>
            </template>
            <div class="loan-application__main">
                <div
                    class="loan-application__content"
                >
                    <div class="loan-application__form">
                        <router-view/>
                    </div>
                </div>
            </div>
        </StepTemplate>
    </div>
</template>

<script>
import './loan-application.scss'

import sbg from '@/mixins/sbg'

export default {
    name: 'LoanApplication',
    mixins: [
        sbg,
    ],
    data() {
        return {
            past: 0,
            days: {
                type: 'days',
                count: 10
            },
            sum: 10000
        }
    },
    computed: {
        title() {
            return this.$DICTIONARY.applicationHeader
        }
    },
    components: {
        Progress: () => import('@/applications/loan-application/components/progress/Progress'),
    },
}
</script>